.App {
  text-align: center;
  /* background-color: #282c34; */
  /* color: white; */
  min-height: 100vh;
  font-size: calc(10px + 0.5vmin);
}

TextField {
  background-color: white;
}

// a {
//   color: #bbf;

//   &:visited {
//     color: #fbf;

//     &:hover {
//       color: #fdf;
//     }
//   }

//   &:hover {
//     color: #bff;
//   }
// }
